/* DayPickerInput */
.container {
  position: relative; }

.overlayWrapper {
  position: relative; }

.overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0px 8px 20px rgba(21, 23, 27, 0.2);
  border: 1px solid #b2b6be; }
